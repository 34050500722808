import React from "react";
import BackgroundImage from "gatsby-background-image";
import PropTypes from "prop-types";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Heading from "components/Heading";

import "./_post-card.scss";

const PostCard = ({ title, image, link }) => {
  return (
    <div className="post-card">
      <BackgroundImage
        Tag="div"
        className="post-card__background"
        fluid={image}
      >
        <AniLink
          to={`/blogas/${link}`}
          className="post-card__link"
          cover
          duration={1.5}
          bg="#3e563e"
        >
          <div className="post-card__heading">
            <Heading headingLevel="h2" headingStyle="h4">
              {title}
            </Heading>
          </div>
        </AniLink>
      </BackgroundImage>
    </div>
  );
};

PostCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default PostCard;
