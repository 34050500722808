import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import { mainNavigation, homesNavigation } from "src/data";
import Header from "components/Header";
import Banner from "components/Banner";
import Layout from "components/Layout";
import Footer from "components/Footer";
import PostCard from "components/PostCard";
import Pager from "components/Pager";
import CookieBar from "components/CookieBar";

import "styles/styles.scss";

const Blog = ({ data, pageContext }) => {
  const { logo, content, blogPosts } = data;
  return (
    <>
      <Header
        main={mainNavigation}
        homes={homesNavigation}
        logo={logo.childImageSharp.fluid}
        logoAlt="Moodu moduliniai nameliai logotipas"
      />
      {content.edges.map((item, idx) => (
        <React.Fragment key={idx}>
          <Banner
            heading={item.node.title}
            isInnerPage
            isCentered
            imageData={item.node.image.fluid}
          />
        </React.Fragment>
      ))}
      <Layout isBlog>
        {blogPosts.edges.map(post => (
          <PostCard
            key={post.node.title}
            title={post.node.title}
            image={post.node.image.fluid}
            link={post.node.slug}
          />
        ))}
      </Layout>
      <Pager pageContext={pageContext} />
      <Footer logo={logo.childImageSharp.fluid} />
      <CookieBar />
    </>
  );
};

Blog.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query Blog($skip: Int!, $limit: Int!) {
    logo: file(relativePath: { eq: "logo-top.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    content: allContentfulBlogPage {
      edges {
        node {
          title
          image {
            fluid(maxWidth: 1600, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }

    blogPosts: allContentfulBlogPost(
      sort: { fields: [createdAt], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          slug
          image {
            fluid(maxHeight: 400, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default Blog;
