import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { ArrowIcon } from "assets/icons";

import "./_pager.scss";

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext;

  return (
    <div className="pager">
      {previousPagePath && (
        <Link to={previousPagePath} className="pager__button">
          <ArrowIcon className="pager__arrow pager__arrow--back" />
          Ankstesnis
        </Link>
      )}
      {nextPagePath && (
        <>
          <Link to={nextPagePath} className="pager__button">
            Sekantis
            <ArrowIcon className="pager__arrow" />
          </Link>
        </>
      )}
    </div>
  );
};

Pager.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Pager;
